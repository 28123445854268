console.group("inicializando o pack de imagens do app")



import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_logo_250.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_logo_400.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_logo_full.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_logo_full_original.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_logo_full_topbar.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_logo_full_topbar_mobile.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_symbol.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_symbol_250.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_symbol_400.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_text.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_text_150.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_text_250.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/app_text_400.png';

import '/var/www/apps/obj/ruby/app/frontend/images/brand/logo_white.png';

import '/var/www/apps/obj/ruby/app/frontend/images/empty_image_large.png';

import '/var/www/apps/obj/ruby/app/frontend/images/pub/bkg.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/pub/header_bkg.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/152-01.svg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CENSINO_FUND_1_NOSSA_METODOLOGIA.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CENSINO_FUND_1_PREPARANDO_PARA_O_FUT.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CENSINO_FUND_1_PROPOSTA_EDUC.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CENSINO_FUND_2_APRENDER_FAZENDO.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CENSINO_FUND_2_NOSSA_METODOLOGIA.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CINFANTIL_MUSICA_BRINQ.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CINFANTIL_MUSICA_BRINQ_2.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CINFANTIL_NOSSA_DIDATICA.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CINFANTIL_PROPOSTA_EDUCACIONAL.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CMEDIO_NOVO_ENSINO_MEDIO.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CMEDIO_PARA_O_ALUNO.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CTERCEIRAO_DIFERENCIAL.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CTERCEIRAO_FULL_TIME.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CTERCEIRAO_VANTAGENS.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/CVESTIBULAR_ESTRUTURA_MAIS_COMPLETA.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/VESTIBULAR_CONSTRUA_O_SEU_RESULTADO.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/biblioteca.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/erica-aponta-camiseta.png';

import '/var/www/apps/obj/ruby/app/frontend/images/site/erik-com-borda-branca.png';

import '/var/www/apps/obj/ruby/app/frontend/images/site/erik-com-borda.png';

import '/var/www/apps/obj/ruby/app/frontend/images/site/erik-sem-borda.png';

import '/var/www/apps/obj/ruby/app/frontend/images/site/erik-white-full.png';

import '/var/www/apps/obj/ruby/app/frontend/images/site/fundamental1_calcada.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/fundamental1_meninas.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/fundamental1_premio.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/fundamental2_desenho.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/fundamental2_large.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/fundamental2_menino.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/fundamental2_mesas.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/fundamental2_patio.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/infantil_bambole.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/infantil_brincadeira.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/infantil_mascaras.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/infantil_pais.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/infantil_sala.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/junior-girl-reading-book-in-studio.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/main_bkg.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/main_bkg.png';

import '/var/www/apps/obj/ruby/app/frontend/images/site/main_bkg_mobile.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/map_marker.png';

import '/var/www/apps/obj/ruby/app/frontend/images/site/medio_sala.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/missao.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/school-red_mini.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/semi_aprovados.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/semi_aprovados2.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/semi_trote.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/sempre_buscando.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/terceirao-3.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/terceirao_sala.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/tradicao_e_infra.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/unimestre_logo.png';

import '/var/www/apps/obj/ruby/app/frontend/images/site/valores.jpg';

import '/var/www/apps/obj/ruby/app/frontend/images/site/visao.jpg';


console.groupEnd();
