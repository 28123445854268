const CALCULATION__SIMPLES = "Simples";
const CALCULATION__ANEXO4 = "Simples (Anexo IV)";
const CALCULATION__REAL = "Lucro real ou presumido";

// calculadora de salários
// exemplo de uso pub/base/_calculator.js
// inicialização:
// var salaryCalculator = new SalaryCalculator({calculationType: TIPODECALCULADO});
// onde TIPODECALCULADO pode ser uma das 3 constantes acima
// no change da calculador chamar o seguinte codigo
// calculatorConfig = {
//     baseSalary: VALOR DO SALARIO BASE,
//     includeVacation: should_consider_vacations.is(":checked"),
//     includeExtraSalary: should_consider_add_salary.is(":checked"),
//     includeRescission: should_consider_taxes.is(":checked")
// }
// lembrar que para VALOR DO SALARIO BASE, deve se transformar com fromMoney() se utilizar o formato financeiro - o uso
// de , no lugar de . avacaçha com o tratamento de float do js
// utilizar o seguinte comando para setar as configurações na calculadora
// salaryCalculator.setConfig(calculatorConfig);
// as seguintes respostas são dadas pela calculadora:
// salaryCalculator.get()  -> retorna o resultado do calculo do salario
// salaryCalculator.getMultiplier()  -> retorna o multiplicador do salario

export default class SalaryCalculator {
    constructor(calculatorParam) {
        this.state = {...{
                calculationType: "",
                config: {
                    baseSalary: 0.00,
                    ratWeight: 3,
                    othersWeight : 5.8,
                    includeVacation: true,
                    includeExtraSalary: true,
                    includeRescission: true
                },
                output: [],
                multiplier: 1.00,
            }, ...calculatorParam};
    }

    setConfig(newConfig) {
        this.state.config = {...this.state.config, ...newConfig};
        this.state.output = this._make()
    }

    _make() {
        let config = this.state.config;

        let salaryWeight = 100;
        let fgtsWeight = 8;
        let ratWeight = config.ratWeight;
        let inssWeight = 20;
        let othersWeight = config.othersWeight;
        let extraSalaryWeight = 8.30;
        let vacationWeight = 2.70;
        let rescissionWeight = 4;

        let percentageWeight = 0.01;


        let fixedMultiplier = 1.00;
        if (this.state.calculationType == CALCULATION__SIMPLES){
            fixedMultiplier = salaryWeight+fgtsWeight;

        }
        else if (this.state.calculationType == CALCULATION__ANEXO4){
            fixedMultiplier = salaryWeight+fgtsWeight+ratWeight+inssWeight;

        }
        else if (this.state.calculationType == CALCULATION__REAL){
            fixedMultiplier = salaryWeight+fgtsWeight+ratWeight+inssWeight+othersWeight;

        }
        let variableMultiplier = 0.00;

        if (config.includeVacation){
            variableMultiplier = variableMultiplier + vacationWeight;
        }

        if (config.includeExtraSalary){
            variableMultiplier = variableMultiplier + extraSalaryWeight;
        }

        if (config.includeRescission){
            variableMultiplier = variableMultiplier + rescissionWeight;
        }
        let fixedCost = config.baseSalary * fixedMultiplier;
        let variableCost = config.baseSalary * variableMultiplier;

        let totalCost = (fixedCost+variableCost)*percentageWeight;
        let totalMultiplier = (fixedMultiplier+variableMultiplier)*percentageWeight;

        this.state.multiplier = totalMultiplier;

        return (Math.round(totalCost * 100) / 100).toFixed(2);
    }

    get() {
        return this.state.output;
    }

    getMultiplier() {

        return this.state.multiplier;
    }

    getTypes() {
        return [CALCULATION__SIMPLES, CALCULATION__ANEXO4, CALCULATION__REAL];
    }

    SIMPLES() {
        return CALCULATION__SIMPLES;
    }
    ANEXO4() {
        return CALCULATION__ANEXO4;
    }
    REAL() {
        return CALCULATION__REAL;
    }
}
